<template>
    <form @submit.prevent="auth" id="login-usuario">
    <div class="form" :key="slug">
        <div class="center">
            <div class="" style="text-align: center">{{lng[lg].iniciar_sesion}}</div>
            <div class="md-headline" style="text-align: center">{{hotel.nombre_establecimiento}}</div>
        </div>

            <md-field>
                <label for="user">{{lng[lg].usuario}}</label>
                <md-select v-model="login.usuario" name="movie" id="movie">
                    <md-option value="">{{lng[lg].seleccione}}</md-option>
                    <md-option :value="user.id_usuario" v-for="user in usuarios" :key="user.id_usuario">{{user.nombre_completo}}</md-option>
                </md-select>
            </md-field>
            <md-field md-has-password>
                <label>{{lng[lg].pass}}</label>
                <md-input v-model="login.password" type="password" ></md-input>
            </md-field>

            <div class="erroralert fadeIn" v-if="error.show">
                {{error.text}}
            </div>
            <div class="actions " style="text-align: center">
                <a href="/resetpassword" v-if="false">Recuperar contraseña</a>
                <md-button type="submit" class="btn-w-100 md-raised md-primary" >{{lng[lg].iniciar_sesion}}</md-button>
            </div>
    </div>
    </form>
</template>
<script>
    import {request, Utility} from "../../helpers";

    export default {
      props: ['slug', 'lang'],
      name : 'login-usuario',
      data() {
        return ({
          hotel   : {},
          usuarios: [],
          error   : {
            show: false,
            text: ''
          },
          login   : {
            establecimiento: "",
            usuario        : "",
            nombre         : "",
            password       : "",
          },
          lng     : {
            es: {
              iniciar_sesion: 'Iniciar sessión',
              usuario       : 'Usuario',
              pass          : 'Contraseña',
              iniciar       : 'INICIAR',
              seleccione    : 'Seleccione ...'
            },
            en: {
              iniciar_sesion: 'Log in',
              usuario       : 'User',
              pass          : 'Password',
              iniciar       : 'START',
              seleccione    : 'Select  ...'
            },
            fr: {
              iniciar_sesion: 'Commencer la session',
              usuario       : 'utilisateur',
              pass          : 'Mot de passe',
              iniciar       : 'COMMENCER',
              seleccione    : 'Sélectionner ...'
            }
          }
        });
      },
      computed: {
        usuariosList() {
          return this.usuarios.map(u => u.nombre)
        },
        lg() { return this.$store.state.active_lang}
      },
      watch   : {
        slug(val) {
          this.loadData();
        }
      },
      beforeMount() {
        this.loadData();
      },
      mounted() {
        this.error.show = false;
        if (this.lang) this.$store.state.active_lang = this.lang
        this.$store.state.loading = false;
      },
      methods: {
        async loadData() {
          this.$store.state.loading = true;
          if (this.slug) {
            this.$store.state.slug = this.slug;
            let response = await request(base_url + '/api/getUsuarios?jwt=' + this.$store.state.jwt, Utility.convertModelToFormData({slug: this.slug}))
            this.$store.state.loading = false;
            if (response.ok) {
              this.usuarios = response.r.data.usuarios;
              this.hotel = response.r.data.establecimiento[0];
              this.login.establecimiento = this.hotel.id_establecimiento;
              return
            }
          }

          this.$router.push({name: 'login-establecimiento'});

        },
        noop() {
          window.alert('noop')
        },
        async auth() {
          this.error.show = false;
          if (this.login.usuario && this.login.password && this.login.establecimiento) {
            this.$store.state.loading = true;

            let data = {
              codigoHotel: this.login.establecimiento,
              usuario    : this.login.usuario,
              pass       : this.login.password,
              lg         : this.lg
            }

            this.$store.state.loading = true;
            let response = await request(base_url + '/entrar/validarDatos?view=json&jwt=' + this.$store.state.jwt, Utility.convertModelToFormData(data))
            this.$store.state.loading = false;

            if (response.ok) {
              this.$store.state.jwt = response.r.data.jwt;
              localStorage.setItem('jwt', this.$store.state.jwt);
              this.$store.dispatch('getServerData');
              this.$router.push({name: 'calendario'})
            } else {
              this.error.show = true;
              this.error.text = response.r.data.message;
            }



          } else {

          }
        }
      }
    };

</script>
<style lang="scss" scoped>
	.md-field {
		width: 100%;
		min-height: 48px;

		display: flex;
		position: relative;
		font-family: inherit;
	}
	button{
		width: 100%;
		margin: 0;
		margin-bottom: 20px;
	}
	.erroralert {
		text-align: center;
		margin-bottom: 10px;
		background-color: #ff5252;
		color: white;
		font-size: 14px;
		padding: 7px;
	}
</style>
